<!-- 退件单详情 -->
<template>
    <div v-loading="loading" class="asnBillDetail">
        <div class="headerBtns">
            <el-button size="small" type="primary" round icon="iconfont icon-zuo" @click="$router.go(-1)"> {{ $t(`m.common.back`) }}
            </el-button>
        </div>
        <el-row>
            <el-col :span="24">
                <el-card>
                    <div style="display: flex;justify-content: space-between;word-break: break-all;">
                        <h3>退件单详情</h3>
                        <div>
                          <el-button class="ml10" type="primary" size="small" @click="onloadSunbmit">保存</el-button>
                          <el-button type="" size="small" @click="$router.go(-1)">取消</el-button>
                        </div>
                    </div>
                    <div class="searchBar">
                        <el-form :model="asnMaster" label-width="120px" ref="formRef">
                            <el-tabs v-model="activeName" @tab-click="handleClick" style="width: 100%;">
                                <el-tab-pane label="基础信息" name="first">
                                    <el-row>
                                        <el-col :span="6">
                                            <el-form-item label="审核状态:">
                                                <el-tag effect="plain" :type="returnStates.find(item => Number(item.value) == asnMaster.state)?.type">
                                                {{ returnStates.find(item => Number(item.value) == asnMaster.state)?.label }}
                                                </el-tag>
                                            </el-form-item>
                                            <el-form-item label="退件单号:">
                                                {{ asnMaster.returnNo||'-' }}
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="6">
                                            <el-form-item label="入库仓:">
                                                <span>
                                                    {{ formatListDataByKey(asnMaster.wareId, false, wares, 'wareName', 'wareId') }}
                                                </span>
                                            </el-form-item>
                                            <el-form-item label="退件订单来源:">
                                                <span>
                                                    {{ formatListDataByKey(asnMaster.billSource, false, billSourceStates, 'label', 'value' ) }}
                                                </span>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="6">
                                            <el-form-item label="关联单号:">
                                                <span>{{ asnMaster.billNo || '-' }}</span>
                                            </el-form-item>
                                            <el-form-item label="物流商:">
                                                {{ asnMaster.supplierName }}
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="6">
                                            <el-form-item label="物流跟踪号:">
                                                <span>{{ asnMaster.trackingNumber || '-' }}</span>
                                            </el-form-item>
                                            <el-form-item label="清点人:">
                                                <span>{{ asnMaster.createUserName || '-' }}</span>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </el-tab-pane>
                                <el-tab-pane label="收货备注" name="two">
                                    <el-row>
                                        <el-col :span="24">
                                            <el-form-item label="备注:" style="margin-bottom: 10px;">
                                                <el-input type="textarea" v-model="asnMaster.returnUdfHs1"></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="24">
                                            <el-form-item label="附件:">
                                                <el-upload
                                                    :file-list="fileList"
                                                    :action="'/api' + '/wms/oss/upload'"
                                                    list-type="picture-card"
                                                    :headers="headers" :limit="10"
                                                    :on-success="handleSuccess">
                                                    <i class="el-icon-plus"></i>
                                                </el-upload>
                                                <span>支持上传10张图片,单张大小不超过10M</span>
                                            </el-form-item>
                                        </el-col>
                                        <!-- :on-preview="handlePictureCardPreview" -->
                                    </el-row>
                                </el-tab-pane>
                            </el-tabs>
                        </el-form>
                    </div>
                </el-card>
                <el-card>
                    <el-tabs type="card" v-model="activeName2" @tab-click="handleClick">
                        <el-tab-pane label="产品信息" name="first">
                            <div class="tableBox">
                                <el-table ref="detailTable" height="400" stripe highlight-current-row
                                    @selection-change="handleSelectionChange" :data="asnDetails" style="width: 100%;"
                                    :header-cell-style="$parent.tableHeaderColor" border show-summary
                                    :summary-method="getSummaries">
                                    <el-table-column type="index" label="序号" width="50"> </el-table-column>
                                    <el-table-column prop="imageUrl" :label="$t(`m.common.picture`)">
                                        <template slot-scope="scope">
                                            <el-image style="width: 80px; height: 80px" :src="scope.row.imageUrl"
                                                :preview-src-list="[scope.row.imageUrl]">
                                            </el-image>
                                        </template>
                                    </el-table-column>
                                    <el-table-column show-overflow-tooltip prop="" label="SKU/产品名称">
                                        <template slot-scope="scope">
                                            <span>{{ scope.row.itemCode }}</span>
                                            <br />
                                            <span>{{ scope.row.itemName }}</span>
                                            <br />
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="returnQuantity" label="退货量"> </el-table-column>
                                    <el-table-column prop="quantity" label="良品"> </el-table-column>
                                    <el-table-column prop="rejectQuantity" label="次品"> </el-table-column>
                                    <el-table-column prop="scrapQuantity" label="报废品"> </el-table-column>
                                    <el-table-column prop="qhNum" label="缺货数量"></el-table-column>
                                </el-table>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="日志" name="2">
                            <div class="tableBox none">
                            <el-table border tooltip-effect="dark myTooltips"
                            highlight-current-row :data="exDetails" style="width: 100%" :header-cell-style="$parent.tableHeaderColor">
                                <el-table-column type="index" width="50" label="序号"> </el-table-column>
                                <el-table-column prop="createTime" label="操作时间"> </el-table-column>
                                <el-table-column prop="serviceText" label="操作事项" show-overflow-tooltip> </el-table-column>
                                <el-table-column prop="userName" label="操作人"> </el-table-column>
                            </el-table>
                            <el-pagination
                                class="pagination-bottom"
                                style="text-align: right;"
                                @current-change="handleCurrentChange"
                                :current-page.sync="logSearchData.pageNum"
                                :page-size="logSearchData.pageSize"
                                layout="total, prev, pager, next, jumper"
                                :total="logSearchData.total"
                            >
                            </el-pagination>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { formatListDataByKey } from '@/utils/DateUtils.js'
import _ from 'lodash'


export default {
    name: 'returnDetal',
    data() {
        return {
            wares: [],
            formData: {},
            loading: false,
            activeName: 'first',
            activeName2: 'first',
            searchData: {
                id: this.$route.params.returnMasterId,
            },
            asnMaster: {},
            asnDetails: [],
            acceptRecords: [],
            exDetails: [],
            carriers: [],
            multipleSelection: [],
            rules: {
                supplierId: [
                    {
                        required: true,
                        message: '请选择货主',
                        trigger: 'blur',
                    },
                ],
                buyType: [
                    {
                        required: true,
                        message: '请选择供应商',
                        trigger: 'blur',
                    },
                ],
            },
            formLabelWidth: '120px',
            headers: {Authorization: `Bearer ` + localStorage.getItem('token')},
            fileList: [],
            logSearchData: {
                wareId: PF.getLocal('wareId'),
                serviceNumber: this.$route.params.returnNo,
                serviceCode: 'return_master',
                pageNum: 1,
                pageSize: 15,
                total: null,
            }
        };
    },
    computed: {
        ...mapState(['billSourceStates', 'returnStates']),
        // supplierId() {
        //     return this.form.asnMaster.supplierId;
        // },
        // isFile() {
        //     if (this.$route.params.fileUserId) {
        //         return true;
        //     } else {
        //         return false;
        //     }
        // },
    },
    watch: {
        // supplierId(val) {
        //     for (let i = 0; i < this.suppliers.length; i++) {
        //         if (this.suppliers[i].supplierId === val) {
        //             this.form.asnMaster.supplierName = this.suppliers[i].supplierName;
        //         }
        //     }
        // },
    },
    methods: {
        formatListDataByKey,
        // 上传成功图片
        handleSuccess(res, file, fileList) {
            let imageUrl = _.map(fileList||[], i => {
                if (i.response) {
                    return i.response.data.url
                }
                return i.url
            })
            if (imageUrl.length) {
                this.asnMaster.imageUrl = JSON.stringify(imageUrl)
            } else {
                this.asnMaster.imageUrl = ''
            }
            console.log(this.asnMaster.imageUrl, 'fillist', fileList);
        },
        // 编辑退件单详情
        onloadSunbmit() {
            IOT.showOverlay('保存中，请稍等...');
            this.$refs.formRef.validate((valid) => {
                if (valid) {
                    IOT.getServerData('/return/masters/update', 'post', { ...this.asnMaster }, (ret) => {
                        IOT.hideOverlay();
                        if (ret.code === 200) {
                            IOT.tips('操作成功！', 'success');
                            setTimeout(() => {
                                this.$router.push('returnBill')
                            }, 500)

                        } else {
                            IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                        }
                    });
                } else {
                    IOT.hideOverlay();
                    console.error('error submit!!');
                    return false;
                }
            });
        },
        // 合计产品信息
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '总计';
                    return;
                }
                const values = data.map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + curr;
                        } else {
                            return prev;
                        }
                    }, 0);
                } else {
                    sums[index] = '';
                }
            });
            return sums;
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },
        handleCurrentChange() {
            this.logSearchData.pageNum = val;
            this.getLogList()
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        getList() {
            this.loading = true;
            let url = '/return/masters/detail';
            IOT.getServerData( url, 'get', this.searchData,
                (ret) => {
                    this.loading = false;
                    if (ret.code === 200) {
                        this.asnMaster = ret.data;
                        // 回显图片
                        let imgList = ret.data.imageUrl ? JSON.parse(ret.data.imageUrl) : []
                        if (imgList.length) {
                            this.fileList = _.map(imgList, i => {
                                return {name: '', url: i}
                            })
                        }
                        this.asnDetails = _.map(ret.data.returnDetailList||[], i => {
                            i.qhNum = Math.abs(i.returnQuantity - ((i.quantity||0)+(i.rejectQuantity||0)+(i.scrapQuantity||0)))
                            return i
                        })
                    } else {
                        IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                    }
                }, true);
        },
        // 获取日志
        getLogList() {
            this.loading = true;
            let url = '/system/log/list';
            IOT.getServerData(url, 'post', this.logSearchData, (ret) => {
                this.loading = false;
                // if (ret.code === 200) {
                    this.exDetails = ret.list
                    this.logSearchData.total = ret.total;
                    this.logSearchData.pageNum = ret.pageNum;
                // } else {
                //     IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                // }
            }, true);
        },
        // 物流商数据源
        getCarrierData() {
            IOT.getServerData('/carriers/list', 'post', {}, (ret) => {
                if (ret.code === 200) {
                    this.carriers = ret.rows;
                } else {
                    IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                }
            });
        },
        resetForm(formName) {
            // 重置
            this.$refs[formName].resetFields();
        },
    },
    created() {
        // this.getCarrierData(); //物流商
        this.getList(); //form
        this.getLogList();
    },
    mounted() {
        PF.getWares((rows) => {
            this.wares = rows;
        })
    },
};
</script>

<style>
.custom-tooltip {
    max-width: 400px !important;
    /* 设置 Tooltip 的最大宽度 */
}
</style>

<style scoped lang="less">
.asnBillDetail {}

:deep(.el-form-item) {
    margin-bottom: 0;
}
</style>
